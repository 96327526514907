@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;1,400&family=Plus+Jakarta+Sans&display=swap");
.typed {
  overflow: hidden;
  white-space: nowrap;
  height: auto;
  display: inline-block;
  /* animation: slide-in 2s forwards, stay 2s 2s, slide-out 2s 4s forwards; */
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes stay {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}
